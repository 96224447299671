import { getCountryCodeAndLanguage, getUrl, getCurrentCountry, getCurrentLanguage } from './utility.service.js'
export const handleListItemClick = async () => {
  const accesstoken = JSON.parse(window.sessionStorage.getItem(`accesstoken`))
  const state = JSON.parse(window.sessionStorage.getItem(`state`))
  const accessToken = accesstoken?.accessToken?.replace(/""/gi, `"`)
  const sessionId = state?.session?.session?.id?.replace(/""/gi, `"`)
  const clientIP = state?.session?.client_ip?.replace(/""/gi, `"`)
  const useremail = state?.loginSuccess?.gatewayCustomer?.email?.replace(/""/gi, `"`)
  const phoneNumber = state?.loginSuccess?.gatewayCustomer?.mobile_phone?.phone_number
  const usercountryCode = phoneNumber?.country_code?.replace(/""/gi, `"`)
  const mobileNumber = phoneNumber?.national_number?.replace(/""/gi, `"`)
  const phoneType = phoneNumber?.type?.replace(/""/gi, `"`)
  const phoneIsverified = phoneNumber?.isVerified?.replace(/""/gi, `"`)
  const routerUrl = getUrl()
  const currentCountry = getCurrentCountry()
  const currentLanguage = getCurrentLanguage()
  const Headers =
    {
      'x-wu-sessionid': sessionId,
      'x-wu-correlationId': sessionId,
      'x-wu-externalRefId': sessionId,
      'x-wu-clientIp': clientIP,
      'Accept-Language': 'en-CA',
      'x-wu-operationName': 'mobileReloadCustomerToken',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    }
  const data = JSON.stringify({
    "name": {
      "first": window.sessionStorage.FirstName,
      "middle": "MI",
      "last": window.sessionStorage.LastName,
      "email": useremail,
      "partnerld": "DING",
      "language": getCountryCodeAndLanguage(),
      "phone": {
        "countryCode": usercountryCode,
        "number": mobileNumber,
        "type": phoneType,
        "isVerified": phoneIsverified,
      }
    }
  })
  const requestParameters = {
    method: 'POST',
    headers: Headers,
    body: data
  }
  let jsonResponse = {}
  try {
    const response = await fetch(`${routerUrl}/mbreload/v1/generate/partnerToken`, requestParameters)
    jsonResponse = await response.json()
    state.dingMobileReload = jsonResponse
    window.sessionStorage.setItem('state', JSON.stringify(state))
    window.sessionStorage.setItem('dingReturnURL',`${routerUrl}/${currentCountry}/${currentLanguage}/web/send-money/start`)
  } catch (error) {
    console.log('error', error)
  } finally {
    window.location.href = `${routerUrl}/${currentCountry}/${currentLanguage}/web/ding-topup`
  }
}
